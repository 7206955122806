.homewrapper {
    overflow-x: hidden;
    
}


.Sections1 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-image: url('../Assets/Images/Landing-page-hero.jpg');
}

.segment1 {
    width: 50rem;
    background-color: #414933;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: lightgray;
    opacity: 80%;
    padding: 15em 5em;
    text-align: center;
}

.Tag {
    font-size: 5rem;
    width: 50rem;
}

.knowmore {
    height: 4rem;
    width: 10rem;
    background-color: #265073;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #265073;
    cursor: pointer;
}

.knowmore:hover {
    background-color: #ECE3CE;
    color: #265073;
    transition: 0.5s;
}

.segment2 {
    width: 50vw;
    padding: 5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.registrationBox {
    width: 21rem;
    background-image: linear-gradient(#ECE3CE, #89917c);
    padding: 3em;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.registrationInside {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1em;
}

.registrationhead, .registrationheaddesc {
    margin: 0;
    cursor: default;
}

.registrationheaddesc {
    font-weight: 400;
}

.inputfields {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1em;
}

.textbox {
    height: 4em;
    width: 23em;
    border: none;
    border-bottom: solid 2px #265073;
}

.submit {
    align-self: center;
    height: 4rem;
    width: 19rem;
    background-color: #265073;
    display: flex;
    justify-content: center;
    align-items: center;
    color: lightgray;
    border: 2px solid #265073;
    cursor: pointer;
}

.submit:hover {
    background-color: #ECE3CE;
    color: #265073;
    transition: 0.5s;
}

.signuptext {
    font-weight: bolder;
}

.Sections2 {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #414933;
    gap: 9em;
}

.segment {
    width: 36em;
    padding: 2rem;
}

.segmentimg {
    height: 55rem;
    width: 36em;
    background-image: url('../Assets/Images/Landing-section2-back.jpg');
    background-size: cover;
}

.segmenthead, .segmenthead2 {
    color: #ECE3CE;
}

.segmenthead2 {
    width: 30em;
    text-align: justify;
    font-weight: 400;
}

.segmentcontent {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
}

.segmentcontenthalf {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
}

.segmentcontentunit {
    width: 18em;
    height: 12em;
    background-color: #6c7064;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
}

.segmentcontentunit:hover {
    transform: scale(1.1);
    transition: 0.5s;
}

.segmenticon {
    height: 1.5rem;
    width: 1.5rem;
    background-color: #ECE3CE;
    border-radius: 20px;
}

.segmenttext {
    width: 13em;
    color: #ECE3CE;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    text-align: center;
}

.Sections3wrapper2 {
    background-image: linear-gradient(45deg, transparent 30%, #414933 100%);
}

.Sections3 {
    height: 50rem;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3em;
}

.section3img {
    height: 35rem;
    width: 35rem;
    background-image: url('../Assets/Images/Landing-section3-small.jpg');
    background-size: cover;
}

.section3content {
    height: 35rem;
    width: 50rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: lightgray;
    text-align: justify;
}

@media (max-width: 1300px) {
    .Tag {
        width: 20rem;
        font-size: 3rem;
    }

    .segment {
        width: 30rem;
    }

    .segmentimg {
        width: 30rem;
    }

    .segmenthead2 {
        width: 29rem;
    }

    .segmentcontentunit {
        width: 14rem;
    }

    .segmenttext {
        width: 10rem;
    }
}

@media (max-width: 1180px) {
    .Sections1 {
        flex-direction: column;
    }

    .segment1 {
        width: 100vw;
    }
}

@media (max-width: 1100px) {
    .segmentimg {
        height: 0;
        width: 0;
        visibility: hidden;
    }

    .Sections2 {
        gap: 0;
    }

    .Sections3 {
        flex-direction: column;
        padding-top: 3em;
    }

    .section3img {
        margin-left: 0;
    }

    .section3content {
        width: 80vw;
        padding-bottom: 3em;
    }
}

@media (max-width: 480px) {
    .segment {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .segmenthead {
        width: 90vw;
        text-align: center;
    }

    .segmenthead2 {
        width: 90vw;
        font-size: 1rem;
    }

    .segmentcontentunit {
        width: 12em;
        height: 10em;
    }

    .segmenticon {
        width: 1em;
        height: 1em;
    }

    .segmenttext {
        width: 10em;
        font-size: 1rem;
    }
}

@media (max-width: 400px) {
    .segmentcontent {
        flex-direction: column;
    }
}
