.ddmenubox{
    background-color: #9faa8b;
    position: absolute;
    top: 12.5em;
}

.ddmenulist{
    display: flex;
    flex-direction: column;
    font-weight: bold;
    
}

.ddmenulistelement{
   text-decoration: none;
   color: black;
   height: 4em;
   width: 15rem;
   display: flex;
   justify-content: center;
   align-items: center;
   border: 1px #8f9b79 solid;
   font-weight: 500;
   
}

.ddmenulistelement:hover{
    background-color: #ECE3CE;
    color: #414933;
    border: 1px #414933 solid;
}

