.Header {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
   overflow-y: hidden;

}

.UpperHeader {
    background-color: #ECE3CE;
    width: 100%;
    height: 9rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    z-index: 1;
}

.logo {
    height: 9rem;
    width: 9rem;
}

.topHeaderContacts {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2em;
}

.topHeaderContactElement {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2em;
}

.topHeaderContactIcon {
    height: 1.5rem;
    width: 1.5rem;
    padding: 0px;
    margin: 0px;
}

.topHeaderContactText {
    padding: 0px;
    margin: 0px;
}

.ApplyNow {
    height: 4rem;
    width: 10rem;
    background-color: #265073;
    color: lightgray;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 2px #265073;
    cursor: pointer;
    font-weight: 400;
}

.ApplyNow:hover {
    height: 4rem;
    width: 10rem;
    background-color: #ECE3CE;
    color: #265073;
    border: solid 2px #265073;
    transition: 0.5s;
}

.LowerHeader {
    width: 100%;
    height: 5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #414933;
    color: lightgray;
    
}

.Links {
    width: 80vw;
    height: 5rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.individualLinks {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5rem;
    width: 10rem;
    cursor: pointer;
    text-decoration: none;
    color: lightgray;
    font-weight: bolder;
}

.individualLinks:hover {
    background-color: #ECE3CE;
    color: black;
    transition: 0.5s;
}



@media (max-width: 830px) {
    .topHeaderContacts {
        visibility: hidden;
        width: 0%;
        height: 0%;
    }
}
