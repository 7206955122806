@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@600&family=Lexend:wght@100..900&display=swap');

body {
    font-family: "Jost", sans-serif;
    font-size: large;
}


.sectionswrapper{
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ECE3CE  ;
    overflow-x: hidden;
    
}


.sections{
    display: flex;
    justify-content: center;
    align-items: center;
}

.sectionhalfimg{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50rem;
    gap: 3em;
    padding: 5em 1em;
}

.sectionhalftext{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 50rem;
    gap: 1em;
    padding: 5em 2em;
  
}


.topic{
    font-family: "Lexend", sans-serif;
    font-weight: 500;
    font-optical-sizing: auto;
    text-align: center;
    text-justify: center;
    margin: 0px;
    font-size: 2.5em;
}

.content{
    font-weight: 500;
    text-align: justify;
    font-weight: 400;
}

.img{
    width: 50rem;
    height: 30rem;
    border-radius: 10%;
}


.section1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    padding: 10rem 8rem;
}

.facultyheader{
    width: 100vw;
    display:flex;
    justify-content: center;
    align-items: center;
    padding: 5rem 0rem 2rem 0rem
}







.aboutussection3{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 5rem 0rem;
    gap: 10rem;
}

.aboutussection3element{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 7rem 3rem;
    border: black 1px solid;
    height: 50rem;
    background-color:#9faa8b ;
    box-shadow: 0em 1px 1em 0.1em gray ;
    cursor:default;
}

.aboutussection3img{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
       
}

.facultyimg{
    height: 15rem;
    width: 15rem;
    border-radius: 50%;
}

.aboutussection3text{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 80%;
}

.aboutussection3topic{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.aboutussection3content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30rem;
    font-weight: 500;
    text-align: justify;
    font-weight: 400;
}

.quote-left{
    height: 3em;
    width: 3em;
    align-self: flex-start;
}

.quote-right{
    height: 3em;
    width: 3em;
    align-self: flex-end;
}


.aboutussection4{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 5rem 0rem;
    gap: 3rem;
    flex-wrap: wrap;
}

.aboutussection4element{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 7rem 3rem;
    border: black 1px solid;
    width: 25rem;
    height: 50rem;
    background-color:#9faa8b ;
    box-shadow: 0em 1px 1em 0.1em gray ;
    cursor:default;
    border-radius: 25px;
}

.aboutussection4element:hover{
    scale: 105%;
    transition: 0.5s;
}

.aboutussection4img{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
       
}

.facultyimg2{
    height: 15rem;
    width: 15rem;
    border-radius: 50%;
}

.aboutussection4text{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 80%;
    
}

.aboutussection4topic{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.aboutussection4content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    text-align: justify;
    font-weight: 400;
}

.aboutusdivider{
    margin-top: 10rem;
    margin-bottom: 10rem;
    width: 60vw;
    height: 1px;
    border-bottom: 0.5px gray solid;
    
}



@media (max-width:1350px){


    .section1{
        padding:5rem 0rem;
    }
    .sections{
        flex-direction: column;
        gap: 0px;
        
        
    }
    .content{
        padding: 0rem 2rem;
    }
    .sectionhalfimg{
        visibility: hidden;
        width: 0px;
        height: 0px;
    }

    .img{
        width: 0rem;
        height: 0rem;
        visibility: hidden;
    }

    .sectionhalftext{
        padding: 0px;
    }

    .aboutussection3{
        flex-direction: column;
    }

    
}


@media (max-width:600px){

    .sectionhalfimg{
        visibility: hidden;
        width: 0%;
        height: 0%;
    }

    .aboutussection3{
        flex-direction: column;
    }

    .aboutussection3element{
        width: 30rem;
        
    }

    .facultyimg{
        height: 10rem;
        width: 10rem;
        border-radius: 50%;
    }

    .aboutussection3topic{
        font-size: 1.5rem;
    }
    .aboutussection3content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        text-align: justify;
        width: 80vw;
        font-weight: 400;

    }

    
    

    
}