.advwrapper{
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    background-color: #ECE3CE  ;
    overflow-x: hidden;
    
}

.coursestopic{
    width: 90rem;
    text-justify:start;
    padding-bottom: 2rem;
}



.coursedesctiptioncontainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: Start;
    width: 100rem;
    margin-bottom: 2rem;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
}

.coursedescription{
    width: 50rem;
    background-color: #b8ac92;
    padding: 1em 4em;
    height: 30em;
}

.courseimagecontainer{
    width: 50rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.courseimg{
    width: 50em;
    height: 32em;
}

@media (max-width: 1499px) {

    .coursestopic{
        width: 60rem;
    }

    .coursedesctiptioncontainer{
        width: 80rem;
        justify-content: center;
        align-items: center;
    }

    .coursedescription{
        width: 40em;
        height: 40em;

    }

    .courseimagecontainer{
        width: 40em;

    }

    .courseimg{
        width: 40em;
        height: 42em;
    }
}

@media (max-width: 1199px) {

    .coursestopic{
        width: 40rem;
    }

    .coursedesctiptioncontainer{
        flex-direction: column;
        width: 80rem;
    }

    .coursedescription{
        width: 40em;
        height: 40em;

    }

    .courseimagecontainer{
        width: 48em;

    }

    .courseimg{
        width: 48em;
        height: 42em;
    }
}

@media (max-width: 759px) {

    .coursestopic{
       text-align: center;
    }

    .coursedesctiptioncontainer{
        flex-direction: column;
    }

    .coursedescription{
        width: 100vw;
        height: auto;
        padding-left: 5em;
        padding-right: 5em;
    }

    .courseimagecontainer{
        width: 48em;

    }

    .courseimg{
        width: 48em;
        height: 42em;
    }
}