.Footer{
    background-color: black;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color:gray;
    overflow-x: hidden;
    font-size: 70%;
}




.grid-container {
  padding-top: 80px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto repeat(2, 0.5fr);
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.header {
  font-weight: bold;
  padding: 10px;
  text-align: center;
}

.cell {
  padding: 10px;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  gap:2em
}

.elementicon{
    height: 1.5rem;
    width: 1.5rem;
}


.elementtext{
    width: 20em;
    display: flex;
    justify-content: center;
    align-items: center;
    
}



@media (max-width:900px){

    .elementtext{
        width: 10em;
    }
}






















